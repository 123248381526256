document.querySelectorAll('article').forEach((el) => {
  el.addEventListener('click', () => {
    document.querySelectorAll('article').forEach((el) => {
      el.classList.remove('hovered');
    });
    el.classList.add('hovered');
  });

  el.addEventListener('mouseenter', () => {
    document.querySelectorAll('article').forEach((el) => {
      el.classList.remove('hovered');
    });
    el.classList.add('hovered');
  });
});

document.getElementById('year').innerHTML = new Date().getFullYear();

document.querySelectorAll('section').forEach((el) => {
  el.style['scroll-margin-top'] = document.querySelector('header').offsetHeight + 'px';
});

document.querySelectorAll('nav a').forEach((el) => {
  el.addEventListener('click', () => {
    document.querySelector(`[data-id="${el.getAttribute('href').replace('#', '')}"]`).scrollIntoView({ behavior: 'smooth' });
  });
});

document.querySelector('#main-header').style['padding-top'] = document.querySelector('header').offsetHeight + 'px';

if (window.innerWidth > 1024) {
  document.addEventListener('mousemove', (e) => {
    document.getElementById('bg-1').style.transform = `translateX(-${(e.clientX / window.innerWidth) / 16 * 100}%) translateY(-${(e.clientY / window.innerHeight) / 16 * 100}%)`;
  });
}

